import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "primary"
    }}>{`Primary`}</h3>
    <button className="btn btn-primary gradient-black">btn-primary</button>
    <button className="btn btn-primary gradient-green">btn-primary</button>
    <button className="btn btn-primary gradient-orange">btn-primary</button>
    <button className="btn btn-primary gradient-purple">btn-primary</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="btn btn-primary gradient-black">btn-primary</button>

<button class="btn btn-primary gradient-green">btn-primary</button>

<button class="btn btn-primary gradient-orange">btn-primary</button>

<button class="btn btn-primary gradient-purple">btn-primary</button>
`}</code></pre>
    <h3 {...{
      "id": "rounded"
    }}>{`Rounded`}</h3>
    <button className="btn btn-rounded gradient-black">btn-rounded</button>
    <button className="btn btn-rounded gradient-green">btn-rounded</button>
    <button className="btn btn-rounded gradient-orange">btn-rounded</button>
    <button className="btn btn-rounded gradient-purple">btn-rounded</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="btn btn-rounded gradient-black">btn-rounded</button>

<button class="btn btn-rounded gradient-green">btn-rounded</button>

<button class="btn btn-rounded gradient-orange">btn-rounded</button>

<button class="btn btn-rounded gradient-purple">btn-rounded</button>
`}</code></pre>
    <h3 {...{
      "id": "outlined"
    }}>{`Outlined`}</h3>
    <button className="btn btn-rounded btn-outlined black-btn">btn-outlined</button>
    <button className="btn btn-rounded btn-outlined green-btn">btn-outlined</button>
    <button className="btn btn-rounded btn-outlined orange-btn">btn-outlined</button>
    <button className="btn btn-rounded btn-outlined purple-btn">btn-outlined</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="btn btn-rounded btn-outlined black-btn">btn-outlined</button>

<button class="btn btn-rounded btn-outlined green-btn">btn-outlined</button>

<button class="btn btn-rounded btn-outlined orange-btn">btn-outlined</button>

<button class="btn btn-rounded btn-outlined purple-btn">btn-outlined</button>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <button className="btn btn-primary" disabled>btn-primary</button>
    <button className="btn btn-rounded" disabled>btn-rounded</button>
    <button className="btn btn-rounded btn-outlined" disabled>btn-outlined</button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button class="btn btn-primary" disabled>btn-primary</button>

<button class="btn btn-rounded" disabled>btn-rounded</button>

<button class="btn btn-rounded btn-outlined" disabled>btn-outlined</button>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      